.pagination-bx .pagination {
    margin: 0;
}
.pagination {
    padding: 10px 0;
	display: block;
}
.pagination li{
	display:inline-block;
	margin: 0 1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.pagination > li > a,
.pagination > li > span {
    background-color: #fff;
    color: #404153;
    padding: 15px 25px;
    font-weight: 600;
    font-size: 14px;
    border: 0;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,0.05);
}
.pagination > li > a:hover,
.pagination > li > span:hover {
    border-color: transparent;
    color: #fff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    border-color: transparent;
}
.pagination-sm .pagination > li > a, 
.pagination-sm .pagination > li > span
.pagination-sm .pagination > .previous > a, 
.pagination-sm .pagination > .next > a{
	font-size: 12px;
	padding: 6px 12px;
}
.pagination-lg .pagination > li > a, 
.pagination-lg .pagination > li > span
.pagination-lg .pagination > .previous > a, 
.pagination-lg .pagination > .next > a{
	font-size: 18px;
    padding: 10px 20px;
}
/* pagination color */
.pagination-bx.primary .pagination > li > a, 
.pagination-bx.primary .pagination > li > span{
	background-color:#1abc9c;
	color:#fff;
	margin: 0 1px;
	border:0;
}
.pagination-bx.primary .pagination > li > a:hover, 
.pagination-bx.primary .pagination > li > span:hover,
.pagination-bx.primary .pagination > li.active > span,
.pagination-bx.primary .pagination > li.active > a{
	background-color:#148f77;
}

.pagination-bx.rounded .pagination > li > a, 
.pagination-bx.rounded .pagination > li > span{
	border-radius: 30px;
	margin:0 1px;
}
.pagination-bx.rounded-sm .pagination > li > a, 
.pagination-bx.rounded-sm .pagination > li > span{
	border-radius: 4px;
	margin:0 1px;
	display: block;
	text-transform: uppercase;
    letter-spacing: 2px;
}
.pagination-bx .pull-right{
	float:right;
}
.pagination-bx .pagination{
	width:100%;
}
.pagination-bx.gray .pagination > li > a, 
.pagination-bx.gray .pagination > li > span{
	background-color:#f6f7f8;
	margin: 0 1px;
	border:0;
}
.pagination-bx.gray .pagination > li > a:hover, 
.pagination-bx.gray .pagination > li > span:hover,
.pagination-bx.gray .pagination > li.active > span,
.pagination-bx.gray .pagination > li.active > a{
	background-color:var(--primary);
	color:#fff;
}
@media only screen and (max-width: 991px) {

}
/*Blog page pagination main*/
.pagination .page-numbers {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    color: #767676;
    padding: 9px 15px;
    font-weight: 600;
    font-size: 12px;
    display: block;
    float: left;
    margin-left: -1px;
}
.pagination .page-numbers.current,
.pagination a.page-numbers:hover {
    background: #3396D1;
    color: #fff;
}
.pagination i {
    font-size: 12px;
}
.pagination li.active a {
    background-color: var(--primary);
    color: #fff;
}
.pagination > li.active > a:hover, 
.pagination > li > a:hover, 
.pagination > li > span:hover{
    color: #000;
}