	/*
  	Flaticon icon font: Flaticon
  	Creation date: 25/03/2019 17:12
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-presentation:before { content: "\f100"; }
.flaticon-employee:before { content: "\f101"; }
.flaticon-rocket:before { content: "\f102"; }
.flaticon-smartphone:before { content: "\f103"; }
.flaticon-increase:before { content: "\f104"; }
.flaticon-employee-1:before { content: "\f105"; }
.flaticon-innovation:before { content: "\f106"; }
.flaticon-employee-2:before { content: "\f107"; }
.flaticon-event:before { content: "\f108"; }
.flaticon-growth:before { content: "\f109"; }
.flaticon-idea:before { content: "\f10a"; }
.flaticon-employee-3:before { content: "\f10b"; }
.flaticon-growth-1:before { content: "\f10c"; }
.flaticon-trophy:before { content: "\f10d"; }
.flaticon-networking:before { content: "\f10e"; }
.flaticon-computer:before { content: "\f10f"; }
.flaticon-wallet:before { content: "\f110"; }
.flaticon-search:before { content: "\f111"; }
.flaticon-computer-1:before { content: "\f112"; }
.flaticon-growth-2:before { content: "\f113"; }
.flaticon-chat-bubble:before { content: "\f114"; }
.flaticon-megaphone:before { content: "\f115"; }
.flaticon-target:before { content: "\f116"; }
.flaticon-clock:before { content: "\f117"; }
.flaticon-line-chart:before { content: "\f118"; }
.flaticon-puzzle:before { content: "\f119"; }
.flaticon-growth-3:before { content: "\f11a"; }
.flaticon-rocket-1:before { content: "\f11b"; }
.flaticon-innovation-1:before { content: "\f11c"; }
.flaticon-time-management:before { content: "\f11d"; }
.flaticon-clipboard:before { content: "\f11e"; }
.flaticon-browser:before { content: "\f11f"; }
.flaticon-browser-1:before { content: "\f120"; }
.flaticon-networking-1:before { content: "\f121"; }
.flaticon-vision:before { content: "\f122"; }
.flaticon-money-bag:before { content: "\f123"; }
.flaticon-loss:before { content: "\f124"; }
.flaticon-compass:before { content: "\f125"; }
.flaticon-package:before { content: "\f126"; }
.flaticon-smartphone-1:before { content: "\f127"; }
.flaticon-briefcase:before { content: "\f128"; }
.flaticon-clipboard-1:before { content: "\f129"; }
.flaticon-growth-4:before { content: "\f12a"; }
.flaticon-laptop:before { content: "\f12b"; }
.flaticon-binoculars:before { content: "\f12c"; }
.flaticon-folder:before { content: "\f12d"; }
.flaticon-calculator:before { content: "\f12e"; }
.flaticon-money:before { content: "\f12f"; }
.flaticon-hourglass:before { content: "\f130"; }
.flaticon-mountain:before { content: "\f131"; }